// Import Swiper React components
import { Grid, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Autoplay, Navigation, Pagination } from "swiper/modules";
import speakers from "./speakers.json";

const SlickSlider = ({ event }) => {
  const data = speakers;

  return (
    <div>
      <Swiper
        autoHeight={true}
        slidesPerView={1}
        // pagination={pagination}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
      >
        {data?.filter(item => item?.event == event).map((item) => (
          <SwiperSlide>
            <Grid
              container
              paddingBottom={4}
              spacing={2}
              alignItems={"flex-start"}
              justifyContent={"space-between"}
              className="slick-item"
            >
              <Grid item lg={12} xs={12}>
                <Typography fontWeight={"bolder"} variant="h6">{item?.name}</Typography>
              </Grid>
              <Grid
                item
                lg={4}
                xs={12}
                textAlign={"right"}
                display={"flex"}
                alignItems={"right"}
                className="image"
              >
                { item?.image != ""  &&<div className="circle-image">
                <img
                  src={`/assets/speakers/new/${item?.image}`}
                  className="image_speaker"
                />
                </div> }
              </Grid>
              <Grid item lg={8} xs={12}>
              <div dangerouslySetInnerHTML={{ __html: item?.biography }} />
              </Grid>
            </Grid>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SlickSlider;
