import { Button, Grid, Typography, useTheme } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import UpdateIcon from "@mui/icons-material/Update";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";

const Overview = ({ event }) => {
  const handleAddToCalendar = () => {
    const eventDate = new Date(2024, 3, 17, 9, 0, 0); // Month is zero-based, so April is represented by 3

    // Create a new calendar event
    const event = {
      title: "Your Event Title",
      location: "Event Location",
      description: "Event Description",
      start: eventDate, // Use the specified eventDate variable
      end: new Date(eventDate.getTime() + 3600000), // End time is one hour later
    };

    // Create a data URI for the calendar event
    const calendarDataURI = encodeURI(
      "BEGIN:VCALENDAR\n" +
        "VERSION:2.0\n" +
        "BEGIN:VEVENT\n" +
        "DTSTART:" +
        event.start.toISOString().replace(/-|:|\.\d+/g, "") +
        "\n" +
        "DTEND:" +
        event.end.toISOString().replace(/-|:|\.\d+/g, "") +
        "\n" +
        "SUMMARY:" +
        event.title +
        "\n" +
        "LOCATION:" +
        event.location +
        "\n" +
        "DESCRIPTION:" +
        event.description +
        "\n" +
        "END:VEVENT\n" +
        "END:VCALENDAR"
    );

    // Create an anchor element and click it programmatically to trigger download
    const link = document.createElement("a");
    link.href = "data:text/calendar;charset=utf-8," + calendarDataURI;
    link.setAttribute("download", "event.ics");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const theme = useTheme()
  return (
    <div id="Overview">
      <Typography variant="h4" sx={{ mb: 2, fontWeight: "bold" }}>Overview</Typography>
      <Typography variant="body1" component={"div"}>
      The world of work is evolving at a rate never before seen – from remote and hybrid offices to the use of artificial intelligence (AI), today’s workplace is not the same 9-to-5 of previous decades. This means that the next generation of professionals needs to be prepared for a different employment setting.
      </Typography>
      <br/>
      <Typography variant="body1" component={"div"}>
 One of the foundational shifts needed in education is the adoption of smart education practices. Smart education leverages advanced technologies to create a more efficient, personalized, and engaging learning environment. Integrating smart education practices not only enhances the employability of graduates but also positions them as proactive, adaptable, and innovative professionals ready to navigate the complexities of the modern workforce, ultimately improving society as a whole.
  
 
      </Typography>
      <br/>
      <Typography variant="body1" component={"div"}>
 To explore these exciting implications, the Centre for the Fourth Industrial Revolution Saudi Arabia (C4IR Saudi Arabia) and Oliver Wyman are hosting a high-level panel discussion of the 4IR Saudi Horizons series: Saudi Arabia and the Future of Smart Education.

      </Typography>

      <Grid
        container
        marginY={10}
        rowSpacing={4}
        justifyContent={"space-between"}
        className="overview_settings"
      >
        <Grid
          item
          className="item"
          lg={3}
          xs={5}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <InsertInvitationIcon fontSize={"large"} sx={{ pb: 2 }} />
          <Typography variant="subtitle" textAlign={"center"}>
          September 24, 2024
          </Typography>
        </Grid>
        <Grid
          item
          className="item"
          lg={3}
          xs={5}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <AccessAlarmIcon fontSize={"large"} sx={{ pb: 2 }} />
          <Typography variant="subtitle" textAlign={"center"}>
          { "10:00am - 11:30am" }

          </Typography>
        </Grid>
        <Grid
          item
          className="item"
          lg={3}
          xs={12}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <LocationOnIcon style={{cursor: "pointer"}} onClick={() =>     window.open("https://maps.app.goo.gl/saFd6RHgFDLL1cgf7", "_blank")} fontSize={"large"} sx={{ pb: 2 }} />
          <Typography style={{cursor: "pointer"}} onClick={() =>     window.open("https://maps.app.goo.gl/saFd6RHgFDLL1cgf7", "_blank")} variant="subtitle" textAlign={"center"}>
          King Abdulaziz City for Science and Technology (KACST), Conference Hall, Building 36         </Typography>
        </Grid>

        <Grid item xs={12} display={"flex"} justifyContent={"center"}>
          <Button variant="contained" onClick={handleAddToCalendar}>
            {" "}
            Add to calendar{" "}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default Overview;
