import {
  Box,
  Button,
  Container,
  Grid,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Countdown from "react-countdown";
import Agenda from "./Components/Agenda";
import LazyImage from "./Components/LazLoadImage";
import Logos from "./Components/Logos";
import Overview from "./Components/Overview";
import Registration from "./Components/Registration";

function DrawerAppBar(props) {
  const [activeSection, setActiveSection] = useState(null);
  const [Type, SetType] = useState("student");

  var eventDate = new Date(2024, 8, 24, 7, 0, 0); // Month is zero-based, so April is represented by 3

  const handleNavigation = (section) => {
    setActiveSection(section);
    const sectionElement = document.getElementById(section);
    sectionElement.scrollIntoView({ behavior: "smooth" });
  };
  const Completionist = () => <span>You are good to go!</span>;

  // Renderer callback with condition
  const renderer = ({ completed, formatted }) => {
    let { hours, minutes, seconds, days } = formatted;
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <Grid container>
          <Grid item xs={3}>
            <Typography variant="h3">{days}</Typography>

            <Typography variant="body1">Days</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h3">{hours}</Typography>
            <Typography variant="body1">Hours</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h3">{minutes}</Typography>
            <Typography variant="body1">Minutes</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h3">{seconds}</Typography>
            <Typography variant="body1">Seconds</Typography>
          </Grid>
        </Grid>
      );
    }
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#16213E",
      },
      secondary: {
        main: "#4399ff",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <div id="Background2">
          <Container sx={{ pt: 3 }} maxWidth="lg">
            <LazyImage
              className={"MainBanner"}
              src={"/assets/Webbanner_smartEdu.png"}
            />
            <Grid container marginY={7.5}>
              <Grid item xs={12} textAlign={"center"}>
                <Box display={"flex"} flexDirection={"column"}>
                  <Countdown
                    renderer={renderer}
                    date={eventDate}
                    className="countdown"
                  />
                  <Grid container marginY={2}>
                    <Grid item xs={12}>
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => handleNavigation("registration")}
                      >
                        {" "}
                        Register Now{" "}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Overview event={Event} />
            <Agenda event={Event} />
            <Registration type={Type} event={Event} />
          </Container>
          {/* <Box className="footer" sx={{pb: 2}}>
            Designed and Developed by{" "}
            <a target="_blank" href="https://www.linkedin.com/in/hamzajomaa/">
              Hamza Jomaa
            </a>
          </Box> */}
        </div>
      </React.Fragment>
    </ThemeProvider>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;
