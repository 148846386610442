import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  where,
  query,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

initializeApp(firebaseConfig);

const firestore = getFirestore();

const Registration = ({ type, event}) => {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    entity: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Check if there's an existing registration with the same email for the same event date
      const duplicateQuerySnapshot = await getDocs(
        query(
          collection(firestore, "registration01"),
          where("email", "==", formData.email)
        )
      );
  
      // If a duplicate is found, show an alert
      if (!duplicateQuerySnapshot.empty) {
        alert("A registration with this email already exists for the selected event date.");
        return; // Stop further execution if a duplicate is found
      }
  
      // If no duplicates found, proceed with the registration
      await addDoc(collection(firestore, "registration01"), {
        ...formData
      });
  
      // Success message and clear the form
      alert("Registration submitted successfully!");
      setFormData({
        firstname: "",
        lastname: "",
        email: "",
        entity: ""
      });
  
    } catch (error) {
      console.error("Error adding document: ", error);
      alert("An error occurred while submitting the registration. Please try again later.");
    }
  };
  

  return (
    <div id="registration">
      <Box component={"form"} onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              label="First Name"
              variant="filled"
              name="firstname"
              value={formData.firstname}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Last Name"
              variant="filled"
              name="lastname"
              value={formData.lastname}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              type="entity"
              label="Entity"
              variant="filled"
              name="entity"
              value={formData.entity}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="email"
              label="Email"
              variant="filled"
              name="email"
              value={formData.email}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
            />
          </Grid>


          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onSubmit={handleSubmit}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Registration;
